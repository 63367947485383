import React from "react"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import PageHeader from "../components/elements/pageHeader"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

const AboutPage = ({ location }) => {
  return (
    <BaseStructure location={location}>
      <Seo title="Credits" />
      <Container className="standardWidth">
        <PageHeader title="Credits" />
        <Typography variant="body1">
          Credits to{" "}
          <Link
            href="https://www.flaticon.com/authors/good-ware"
            title="Good Ware"
          >
            Good Ware
          </Link>{" "}
          from{" "}
          <Link href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </Link>{" "}
          for the leaf icon
        </Typography>
        <Typography variant="body1">
          Built with
          {` `}
          <Link
            href="https://www.gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gatsby
          </Link>
        </Typography>
      </Container>
    </BaseStructure>
  )
}

export default AboutPage
